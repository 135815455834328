import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Products.vue")
  },
  {
    path: "/community/activity",
    name: "Community",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Activity.vue")
  },
  {
    path: "/community/joinus",
    name: "Community",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Joinus.vue")
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Support.vue")
  },
  {
      path: "*",
      redirect: "/"
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  server: "http://localhost:8081",
  routes
});



export default router;
