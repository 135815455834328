<template>
	<div class="main_container">
		<div class="home_container">
			<div class="home">
				<div class="banner_bg">
					<div class="colorblock">
						<div class="color"></div>
					</div>
					<div class="colorblock">
						<div class="color"></div>
					</div>
					<div class="colorblock">
						<div class="color"></div>
					</div>
				</div>
				

				<div class="banner">
					<div class="content">
						<img src="@/assets/solgan.gif" />
						<!-- <h1>TH661 HOME CAMERA</h1> -->
						<!-- <h3>The world<br/>through my<br/>eyes!</h3> -->
			    		<!-- <h2>TENVIS high definition<br/>p2p smart ip camera<br/>night vision easy</h2> -->
					</div>

					<div class="scroll" @click="scroll_product">
			    		<span>Scroll</span>
			    		<img class="banner_icon" src="@/assets/arrow_down.png" style="opacity: 1; transform: translateX(0px);" />
			    	</div>
			    </div>

				<div class="ads">
			     	<img src="@/assets/ads.gif" />
			    </div>
			</div>
		</div>
		<div class="top">
		</div>
		<div class="products" id="product_c" >
				<div class="content_container"  >
					<div style="float:left" >
						<p class="color_bule size24" style="font-weight: normal;">RECOMMEND</p>
						<h1 class="size60">{{hot_title}}</h1>
						<span class="color_grayAA size20">{{hot_desc}}</span>
						<HomeCard class="card1" v-if="hots && hots.length>0" :product="hots[0]" :index=1></HomeCard>
					</div>
					<div class="card_right">
						<HomeCard class="card2" v-if="hots && hots.length>1" :product="hots[1]" :index=2></HomeCard>
						<HomeCard class="card3" v-if="hots && hots.length>2" :product="hots[2]" :index=3></HomeCard>
					</div>
				</div>
		</div>

		<div class="top-product" ref="topproduct" >
			<img src="@/assets/top-product-des.png" />
		</div>

		<div class="clients">
			<span class="h2">TESTIMONIAL</span>
			<div class="h1">What Says Our Happy Clients</div>
			<div class="messages">
				<div class="message"><MessageBox :start4 ="false" :msg="msg_arr[0]"></MessageBox></div>
				<div class="message message2"><MessageBox  :start4 ="false"  :msg="msg_arr[1]"></MessageBox></div>
				<div class="message"><MessageBox :start4 ="false" :msg="msg_arr[2]"></MessageBox></div>
			</div>
			<Subscribe />
		</div>
		<LayerMessage :show="showMsg"/>
	</div>
</template>

<script>
 import HomeCard from "@/components/HomeCard.vue";
 import MessageBox from "@/components/MessageBox.vue";
 import Subscribe from "@/components/Subscribe.vue";
 import LayerMessage from "@/components/LayerMessage.vue";

export default {
  name: "Home",
  data(){
  	return {
  		fixedBg:false,
  		hot_title:'',
  		hot_desc:'',
  		msg_arr:["My son bought the 2 Pack IP cameras on Amazon to monitor our puppy without my knowledge. At first, I thought it must be a kinda like waste of money since I never heard of this kind of product. However, after setting it takes about 5 mines for two cameras in total, the picture quality exceeded my expectations, even in the dark night...",
  		"I didn't know what to expect when I bought this camera to monitor our pets. I am very pleasantly surprised. It was super easy to install everythin....literally it was download the app, scan the code on the back of the camera and set up a password. Everything is just automatic and does it on its own. Super easy. And the camera...",
  		"This camera is very easy to use with a lot of features including night vision, motion detection and zoom. Good quality picture. I haven't used the motion detection feature yet and have not recorded onto MicroSD card yet but am hopeful it will record seamlessly. It will record to your phone. See the photo for a picture of what the app looks like with..."
  		],
  		showMsg:false,
  	};
  },
  mounted(){
	window.addEventListener("beforeunload",this.scrollTop);
	this.setHomeCardTitle("Privacy? Indoor/Outdoor?","There is always a type of camera that can meet your needs.");
	let me = this;
  },
  computed:{
  	products(){
  		return this.$store.state.products;
  	},
  	hots(){
  		return this.$store.state.hots;
  	}
  },
  methods:{
  	scrollTop(){
  		window.scrollTo(0,0);
  	},
  	setHomeCardTitle(card_title,card_desc){
  		this.hot_title = card_title;
  		this.hot_desc = card_desc;
  	},
  	scroll_product(){
  		this.$el.querySelector(`#product_c`).scrollIntoView({
	        behavior: "smooth",  // 平滑过渡
	        block:    "start"  // 上边框与视窗顶部平齐。默认值
	    });
  	}
  },
  components: {
  	HomeCard,
  	MessageBox,
  	Subscribe,
  	LayerMessage
  }
};
</script>

<style scoped >

h3{
	font-family: 'Impact';
	font-size: 100px;
	color: #fff;
}

@-webkit-keyframes arrow-scroll{
	from{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
	to{-webkit-transform:translate3d(0,30px,0);transform:translate3d(0,30px,0);opacity:1}
}

@keyframes arrow-scroll{
	from{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
	to{-webkit-transform:translate3d(0,30px,0);transform:translate3d(0,30px,0);opacity:1}
}


.banner_icon{
	display: block;
	width: 27px;
	height: 66px;
    stroke: #fff;
    fill: transparent;
    -webkit-animation: arrow-scroll 1.5s ease-in-out infinite alternate;
    animation: arrow-scroll 1.5s ease-in-out infinite alternate;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    margin-bottom: 50px;
    margin-top:30px; 
}

.clients{
	padding-top: 75px;
	text-align: center;
	background: #ffffff;
	padding-bottom: 100px;
}

.clients .h2{
	width: auto;
	height: 25px;
	font-size: 20px;
	font-family: Muli;
	font-weight: bold;
	line-height: 16px;
	letter-spacing: 5px;
	color: #06A2EC;
	opacity: 1;
}

.clients .h1{
	margin-top: 15px;
	width: auto;
	height: 45px;
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 16px;
	color: #333333;
	opacity: 1;
}

.main_container{
	background-image: url('../assets/top-product.jpg'); 
	background-attachment: fixed; 
}

.content_container{
	width: 1390px;
	margin:0 auto; 
}

.home_container{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #ffffff;
}


.home{
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	animation: move-in 2s 1s ease;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1.3);
	display: flex;
    justify-content:center;
    align-items:center;
    overflow: hidden;
}

.fixedbg{
	position: absolute !important;
}
.top{
	width: 100%;
	height: calc(100vh);
	overflow: hidden;
}

.products{
	width: 1920px;
	height: 1100px;
	padding-top: 200px;
	overflow: hidden;
	background-image: url('../assets/pd_bg.gif');
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position:bottom right;
}

.messages{
	width: 1390px;
	margin: 0 auto;
	margin-top: 55px;
	height: 500px;
}

.message{
	float:left;
}

.message2{
	margin-left:30px;
	margin-right:30px; 
}

.card1{
	margin-top: 150px;margin-left: 100px;
}

.card2{
	margin-top: 50px;
}

.card3{
	margin-top:100px;
}

.card_right{
	float:left;margin-left: 150px;
}


.top-product{
	width: 100%;
	height: 657px;
	display: flex;
    justify-content:center;
    align-items:center;
}

.top-product img{
	width: 861px;
	height: 310px;
}

.banner_bg{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}


.banner{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 1390px;
	height: 100%;
	display: flex;
	flex-direction: column;
	word-break: break-all;
	animation: fade-in 2s 1s ease;
	opacity: 0;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1.3);
}

@keyframes ads-right-in{
    to{
      top:0px;
      right:-40px;
      opacity: 1;
    }
  }

  @-webkit-keyframes ads-right-in{
    to{
      top:0px;
      right:0px;
      opacity: 1;
    }
  }

.ads{
	overflow: hidden;
	margin:0;
	padding:0;
	width: 820px;
	right: -780px;
	top: 0px;
	position: absolute;
	height: 100%;
	background: #202b39;
	animation: ads-right-in 1.2s 2.8s ease;
	opacity: 0;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1.3);
}

.banner .content{
	/*width: 867px;*/
	width: 1150px;
	flex: 1;
	text-align: left;
/*	margin-top: 150px;
	margin-left: 10px; */
	display: flex;
	justify-content: left;
	align-items: center;
	overflow: hidden;
}

.content img{
	width: 1150px;
}

.scroll{
	color:#fff;
	height: 180px;
	font-size: 16px;
	cursor: pointer;
	margin-left: 280px;
}


.banner .content h1{
	font-size: 60px;
	color: #202B39;
	display: block;
	font-weight: bold;
}

.banner .content h2{
	font-size: 46px;
	color: #fff;
	display: block;
	font-weight: bold;
}


@keyframes springish {
  0.00% {transform: translate3d(100.00px, 0, 0) scale(0);}
  10.16% {transform: translate3d(-41.06px, 0, 0) scale(1.4106);}
  21.39% {transform: translate3d(16.09px, 0, 0) scale(0.8391);}
  32.62% {transform: translate3d(-6.31px, 0, 0) scale(1.0631);}
  43.85% {transform: translate3d(2.47px, 0, 0) scale(0.9753);}
  55.08% {transform: translate3d(-0.97px, 0, 0) scale(1.0097);}
  66.31% {transform: translate3d(0.38px, 0, 0) scale(0.9962);}
  77.54% {transform: translate3d(-0.15px, 0, 0) scale(1.0015);}
  88.77% {transform: translate3d(0.06px, 0, 0) scale(0.9994);}
  100.00% {transform: translate3d(-0.02px, 0, 0) scale(1.0002);}
}

@keyframes scaleHeight {
    from {
        height: 80%;
        opacity: 0.8;
    }
    to {
        height: 100%;
        opacity: 1;
    }
}


.springish {
  animation: springish; 
  animation-iteration-count: infinite;
  animation-duration: 2.38s;
  animation-timing-function: cubic-bezier(0.445,  0.050, 0.550, 0.950);
}



.colorblock{
	width: 100%;
	height: 33.4%;
}

.color{
	width: 100%;
	background-color:#06A2EC;
	animation: scaleHeight 2s 1s ease-in;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1);
}

</style>
