import Vue from "vue";
import "lib-flexible";
import App from "./App.vue";
import router from "./router";
import VueResource from 'vue-resource'
import Vuex from 'vuex'
import store from './store'
import global from './config'
import Toast from '@/components/Toast.js'


//挂载Vuex
Vue.use(Vuex)
Vue.use(VueResource);
Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;

Vue.http.interceptors.push((request, next) =>{
	console.log(request.headers);
	 var disableloading = request.headers.get("disableloading");
	 if(!disableloading){
	 	store.commit('request_loading',true);
	 }
	 
	 next((response) => { 
	 	if(!disableloading){
			store.commit('request_loading',false);
		}
	   return response;
	 }); 
	}); 


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
