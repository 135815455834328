<template>
      <div :id="canScoll?'main_container':'main_container_no_scroll'">
          <transition name="fade">
            <div id="loading" v-if="isLoading">
              <img src="~@/assets/loading.gif" />
            </div>
          </transition>
          <div id="request_loading" v-if="request_loading" >
            <div class="request_loading_bg">
                    <img src="~@/assets/request_loading.gif" />
            </div>
          </div>

          <div id="app">
                <Navigate :home="isHome" :select="pageName" :isTopin="topin" />
              <router-view />
              <Bottom />
              <div class="chat" @click="livechat" v-show="showchat">
                <img src="~@/assets/chat.png" />
              </div>
              
          </div>
      </div>
</template>

<script>
// @ is an alias to /src
import Bottom from "@/components/Bottom.vue";
import Navigate from "@/components/Navigate.vue";
import LayerMessage from "@/components/LayerMessage.vue";

export default {
  name: "Home",
  data(){
    return {
      isHome:true,
      pageName:"Home",
      isLoading:true,
      canScoll:true,
      topin:true,
      products:[],
    };
  },
  created(){
    let me = this;
    this.getProducts();
    this.getHotProducts();
    setTimeout(function () {
      me.isLoading = false;
    },1000);
  },
  components: {
    Bottom,
    Navigate,
    LayerMessage
  },
  computed:{
    request_loading(){
      return this.$store.state.request_loading;
    },
    showchat(){
      if(this.canScoll){
        return true;
      }else{
        return false;
      }
    }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    livechat(){
      if(!this._isMobile()){
         window.open("https://tenvis.afterservice.vip/",'_blank');
      }else{
         window.open("https://tenvis.afterservice.vip/");
      }
    },
    getProducts(){
      console.log("getProducts")
      let me = this;
       console.log("getProducts1")
      this.$http.get(global.SERVER_URL+'/openapi/products',{headers:{'disableloading':'true'}}).then(response => {
         if(response.data && response.data.code ===0){
           me.$store.commit('edit_products',{ products: response.data.data })
         }
      }, response => {
         setTimeout(function () {
            me.getProducts();
         },3000);
      });
    },
    getHotProducts(){
      let me = this;
      this.$http.get(global.SERVER_URL+'/openapi/hot/products',{headers:{'disableloading':'true'}}).then(response => {
         console.log(response.data);
         if(response.data && response.data.code ===0){
           me.$store.commit('edit_hots',{ products: response.data.data });
         }
      }, response => {
         console.log("request failed");
         setTimeout(function () {
            me.getHotProducts();
         },3000);
      });
    }
    ,
    setScroll(){
      let me = this;
      if(me.isHome){
          me.canScoll = false;
          setTimeout(function () {
               me.isLoading = false;
            },1000);
          setTimeout(function () {
            me.canScoll = true;
          },4000);
        }else{
          if(me.isLoading){
            me.canScoll = false;
            setTimeout(function () {
               me.isLoading = false;
            },1000);
            setTimeout(function () {
               me.canScoll = true;
            },1000);
          }else{
            me.canScoll = true;
          }
        }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        if(route.matched.length>0){
            if(route.matched[0].name == "Home"){
              console.log('route in home'+route.matched.length);
               this.isHome = true
               this.pageName = "Home";
               this.topin = true;
               this.isLoading = true;
               this.setScroll();
          }else{
            if(route.matched[0].name == "Home" || route.matched[0].name == "About" || route.matched[0].name == "Products" || route.matched[0].name == "Support" || route.matched[0].name == "Community"){
              this.isHome = false;
              console.log('route in name:'+route.matched[0].name);
              this.pageName = route.matched[0].name;
              if(this.pageName != "Home"){
                this.topin = false;
                this.setScroll();
              }
            }
          }
        }
        
      },
      immediate: true
    }
  }
};

</script>
<style>
   #main{
      height:calc(100vh);
      overflow: hidden;
   }

  #main_container{
    width:100%;
    height:auto;
    overflow:hidden;
  }

  #main_container_no_scroll{
    width:100%;
    overflow: hidden;
    height: 100vh;
  }

  @font-face {
    font-family: muli;
    src: url('~@/assets/font/Muli-Regular.ttf');
  }

  @keyframes fade-in {  
      0% {opacity: 0;}/*初始状态 透明度为0*/  
      40% {opacity: 0;}/*过渡状态 透明度为0*/  
      100% {opacity: 1;}/*结束状态 透明度为1*/  
  }  

  @-webkit-keyframes fade-in {/*针对webkit内核*/  
      0% {opacity: 0;}  
      40% {opacity: 0;}  
      100% {opacity: 1;}  
  } 

  

  @keyframes move-in{
    to{
      top:0px;
      left:0px;
      opacity: 1;
    }
  }

 @-webkit-keyframes move-in{
    to{
      top:0px;
      left:0px;
      opacity: 1;
    }
  }

@keyframes move-right-in{
    to{
      top:0px;
      right:0px;
      opacity: 1;
    }
  }

  @-webkit-keyframes move-right-in{
    to{
      top:0px;
      right:0px;
      opacity: 1;
    }
  }

 

  body{
    margin: 0px;padding: 0px;
    font-family:muli, Arial, sans-serif;
  }

  .color_bule{
    color: #06A2EC;
  }

  .color_grayAA{
    color:#AAAAAA;
  }

  .size20{
    font-size: 20px;
  }

  .size24{
    font-size: 24px;
  }

  .size60{
    font-size: 60px;
  }

  .chat{
      position: fixed;
      top: 50%;
      right: 0px;
      width: 120px;
      height: 120px;
      cursor: pointer;
      z-index: 9999;
  }

   .chat img{
      width: 120px;
      height: 120px;
   }


  #loading {
    position: fixed;
    left: 0;
    top: 0;
    background-color:#fcfcfc;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content:center;
    align-items:center;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  #loading img{ width: 400px; }

  .gm-scrollbar.-vertical {
    background-color: #00000000；
  }

  /* horizontal scrollbar track */
  .gm-scrollbar.-horizontal {
    background-color: transparent;
  }

  /* scrollbar thumb */
  .gm-scrollbar .thumb {
    background-color: #202b39;
  }

  .gm-scrollbar .thumb:hover {
    background-color: #202b39;
  }

.request_loading_bg{
  width: 100%;
  height: 100%;
  position:fixed;
  _position:absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  background:rgba(0,0,0,0.5);
  -moz-opacity:0.5;
  filter:alpha(opacity=50);
  z-index:999;
  text-align: center;
  display: flex;;
  align-items: center;
  justify-content: center;
}

.request_loading_bg img{
  width: 50px;
  height: 50px;
}

</style>
