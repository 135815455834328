<template>
	 <transition name='fade'>
		  <div class="mask" v-if="show" @touchmove.prevent @click="show=false">
			   <div class="window" @click.stop>
			   		<img class="close" src="@/assets/close.png" @click="show=false" />
				    <img class="img" src="@/assets/message_head.png" alt="">
					<h1>{{title}}</h1>
					<p v-html="content"></p>
			   </div>
		  </div>
	 </transition>
</template>
 
<script>
	export default {
		 name: 'LayerMessage',
		 props: {
		  	show: Boolean,
		 },
		 data () {
		  return {
		   title: 'Important Noitce!',
		   content: 'We are sorry to inform that there was a fire accident in our server room, which led to an unstable connection to PART of the users. It is expected to take 7 working days to recover.<br/> If you encounter similar incidents, please contact our <a style="color:#06A2EC" href="https://tenvis.afterservice.vip/">https://tenvis.afterservice.vip/</a>. Once again, please accept our apology for causing the inconvenience.'
		  }
		 },
		 created () {
		 }
	}
</script>
 
<style scoped>
.fade-enter,.fade-leave-active {
  opacity: 0;
}

.fade-enter-active,.fade-leave-active {
  transition: opacity .3 5s;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999999;
  background:rgba(0,0,0,0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.window{
   min-height: 564px;
   height: auto;
   width: 765px;
   background: #fff;
   border-radius:2px;
   text-align: left;
   position: relative;
}
 
.img{
	width: 765px;
	height: 304px;
}

h1{
	font-size:26px;
	padding-left: 40px;
}

p{
	padding-left: 40px;
	padding-right: 40px;
	font-size: 14px;
	color:#444444;
	line-height: .2rem;
	margin-bottom: 30px;
}
 
.close{
	position: absolute;
	right: 0px;
	top:0px;
	padding: 20px;
	cursor: pointer;
}

</style>

 
 