import { render, staticRenderFns } from "./Navigate.vue?vue&type=template&id=4c73baac&scoped=true&"
import script from "./Navigate.vue?vue&type=script&lang=js&"
export * from "./Navigate.vue?vue&type=script&lang=js&"
import style0 from "./Navigate.vue?vue&type=style&index=0&id=4c73baac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c73baac",
  null
  
)

export default component.exports