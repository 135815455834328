<template>
  <div class="container">
	  <div class="msg-box">
				<span v-text="msg"></span>
	  </div>
	  <div class="arrow"></div>
	  <div class="bottom">
	  		<img src="@/assets/touxiang.png" class="head" />
	  		<div class="right">
	  			<div>Customer</div>
	  			<div :class="{'start4':start4}">
	  				<img src="@/assets/star2.png" />
	  			</div>
	  		</div>
	  </div>
  </div>
  
</template>
<script>
export default {
  name: "MessageBox",
  data(){
  	return { 
  	}
  },
  props: {
  	start4:true,
  	msg:"",
  },
  methods: {
  }
};
</script>

<style scoped>
.container{
}

.msg-box{
	width: 380px;
	height: 220px;
	padding:30px;
	background: #FFFFFF;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	opacity: 1;
	font-size: 16px;
	line-height: 35px;
	color: #202B39;
	text-align: left;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bottom{
	width: 440px;
	height: 80px;
	margin-top: 15px;
	display: flex;
}

.bottom .head{
	height: 80px;
	display: block;
	float: left;
}

.bottom .right{
	text-align: left;
	padding-left: 15px;
	padding-top: 10px;
	flex: 1;
}

.bottom .right div{
	font-size: 20px;
	font-family: Muli;
	font-weight: bold;
	line-height: 30px;
	color: #202B39;
}

.start4{
	width: 75px;
	overflow: hidden;
}

.bottom .right img{
	width: 100px;
}



.msg-box span{ opacity: 0.7; }

.arrow{
	position: relative;
	top: -20px;
	width: 0px;
	height: 20px;
	border-bottom:40px solid transparent;border-left:55px solid #fff;
	filter: drop-shadow(0px 40px 20px rgba(0, 0, 0, 0.1));
}
</style>

