<template>
		<div :class="{'navigate_container': true,'black_bg':!isTopStyle,'fixed':searchBarFixed || isOtherPage,'top':showTopIn}" ref="navbar" v-show="!isHidden">
			  <div :class="{navigate:true}">
			  	<router-link to="/">
				  	<img class="logo" v-if="isTopStyle" src="@/assets/default_logo.png" />
				  	<img class="logo" v-else src="@/assets/logo.png" />
			  	</router-link>
			    <ul :class="isTopStyle?'menu':'menu_home'">
			    	<li :class="select == 'Home' || !select?'select':''" ><a @click="toHome">HOME</a></li>
			    	<li :class="select == 'Products'?'select':''"><router-link to="/products">PRODUCTS</router-link></li>
			    	<li :class="select == 'Support'?'select':''"><router-link to="/support">SUPPORT</router-link></li>
			    	<li :class="select == 'About'?'select':''"><router-link to="/about">ABOUT</router-link></li>
			    	<li :class="select == 'Community'?'select':''"><a href="javascript:;" @click.stop="comunity_click">COMMUNITY</a></li>
			    </ul>
			    <div class="community_menu" v-show="showCommunity" ref="community">
			  		<a @click="goto(true)">Join us</a>
			  		<a @click="goto(false)">Activity</a>
			  	</div>
			  </div>
		</div>
</template>

<script>
export default {
  name: "Navigate",
  props: {
  	home : Boolean,
  	select : String,
  	isTopin: Boolean,
  },
  data(){
  	return {
  		scrollTopStyle:false,
  		isHidden:false,
  		searchBarFixed:false,
  		navbarHeight:0,
  		lastTop:0,
  		showCommunity:false,
  		scrollTop:0,
  		scrollTopIn:true,
  		isOtherPage:false
  	};
  },
  mounted () {
  	let me = this;
	window.addEventListener('scroll', this.handleScroll)
	window.addEventListener('click',e=>{
		if(!me.$refs.community.contains(e.target)){
			me.showCommunity = false;
		}
	})
  },
  computed:{
  	isTopStyle(){
  		if(this.home){
  			if(this.scrollTop <=0){
  				return true;
  			}
  			return this.scrollTopStyle;
  		}else{
  			return false;
  		}
  	},
  	showTopIn(){
  		if(this.isTopin && this.scrollTopIn){
  			this.searchBarFixed = false;
  			return true;
  		}else{
  			return false;
  		}
  	}
  },
  watch:{
	  $route(to,from){
	  	if(to && to.name){
	  		this.scrolltop();
	  	}
	    if(to.name == "Home"){
	    	this.searchBarFixed = false;
	    	this.isOtherPage = false;
	    }else{
	    	this.isOtherPage = true;
	    }
	  }
	},
  methods:{
  	toHome(){
  		this.scrolltop();
  		let me = this;
  		setTimeout(function () {
           me.$router.push("/");
        },700);
  	}
  	,
  	scrolltop(){
  		window.scrollTo(0,0);
  	},
  	comunity_click(){
  		this.showCommunity = true;
  	},
  	goto(isJoinus){
  		if(isJoinus){
  			this.$router.push("/community/joinus");
  		}else{
  			this.$router.push("/community/activity");
  		}
  		this.showCommunity=false;
  	},
  	handleScroll () {
  		
		this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

		if(this.$refs.navbar.offsetHeight>0){
			this.navbarHeight = this.$refs.navbar.offsetHeight;
		}
	
		var offsetTop = document.documentElement.clientHeight -  this.navbarHeight * 2.5;

		var scroll = this.scrollTop - this.lastTop;
		this.lastTop = this.scrollTop;
		if(this.home){
			if(scroll<0){
				if (this.scrollTop > offsetTop) {
					this.scrollTopIn = false;
					this.scrollTopStyle = false;
					this.searchBarFixed = false;
					this.isHidden = true;
				}else if(this.scrollTop< this.navbarHeight * 1){
					this.scrollTopIn = false;
					this.searchBarFixed = false;
					this.scrollTopStyle = true;
					this.isHidden = false;
				}
				else {
					this.scrollTopStyle = false;
					this.searchBarFixed = true;
					this.isHidden = false;
				}
			}else if(this.scrollTop > offsetTop) {
					this.scrollTopIn = false;
					this.scrollTopStyle = false;
					this.searchBarFixed = false;
					this.isHidden = true;
			}
		}else{
			this.searchBarFixed = false;
			this.scrollTopStyle = false;
			this.scrollTopIn = true;
			this.isHidden = false;
		}
		
	},
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.community_menu{
	width: 164px;
	height: 82px;
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(3, 3, 3, 0.08);
	opacity: 1;
	border-radius: 10px;
	position: absolute;
	right: 0px;
	top:90px;
	padding: 20px 0px;
	overflow: hidden;
}

.community_menu a{
	font-size: 20px;
	line-height: 35px;
	color: #999999;
	text-decoration: none;
	display: block;
	width: 100%;
	font-family: Ebrima;
	text-align: left;
	padding-left:30px; 
	margin-bottom: 10px;
	cursor: pointer;
}

.community_menu a:hover{
	color: #06A2EC;
}

.navigate_container{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width:100%;
	height:80px;
    display: flex;
    justify-content:center;
    align-items:center;
}

.fixed {
	position: fixed;
}

.black_bg{
	background-color: #202B39;
}

.navigate{
  width: 1390px;
  font-size: 16px;
  position: relative;
}

.navigate ul{
	list-style: none;
	float: right;
}

.navigate ul li{
	float: left;
	margin-left: 80px;
}

.menu li a{
	color:#fff;
	text-decoration: none;
	cursor: pointer;
}

.menu_home li a{
	color:#fff;
	text-decoration: none;
	cursor: pointer;
}

.menu_home li a:hover,.menu li a:hover{
	color:#06A2EC;
}

.menu .select a {
	color:#202B39 !important;
}

.menu_home .select a{
	color:#06A2EC !important;
}

.logo{
  width: 140px;
  height: 38px;
  float: left;
}


.top{
	top:-100%;
	left: 0;
	animation: move-in 1s 3.5s ease;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1.31);
}

</style>
