<template>
  <div class="home_card" @mouseover="mouseOver" 
        	@mouseleave="mouseLeave" @click="go2Product()">
  	<div :class="bgclass"></div>
  	<div class="left">
	 	<div :class="{'number':true,'number-font':true,'color_active':isHover}">{{ "0." + index }}</div>
	 	<div :class="{'model':true,'number-font':true,'color_active':isHover}">{{product?product.hot_title:''}}</div>
	 	<div :class="{'des':true,'color_active':isHover}">{{product?product.hot_desc:''}}</div>
	 	<div class="buttons">
	 		<a :href="product?product.buy_url:'#'"  :target="{_blank:product ,self: !product }" @click.stop="none"><div class="buton_normal">Buy Now</div></a>
	 		<a @click.stop.prevent="goSupport"><div class="buton_normal" style="margin-top: 20px;">Support</div></a>
	 	</div>
 	</div>
 	<div class="right" >
 		<img :src="product?get_pic(product.hot_pic):''" :class="{'img':true,'img_active':isHover}" />
 		<img :src="product?get_pic(product.line_pic):''" :class="{'img_hover':true,'img_hover_active':isHover}" />
 	</div>
  </div>
</template>

<script>
export default {
  name: "HomeCard",
  data(){
  	return { 
  		bgclass:{bg:true},
  		isHover:false,
  	}
  },
  props: {
  	product: Object,
  	index: Number,
  },
  methods: {
  		none(){

  		}
  		,go2Product(){
  			if(this.product && this.product.id){
  				var path = '/products';
				this.$router.push({
		          path: path,
		          query:{
		          	id:this.product.id
		          }
		        });
  			}
  		},
        mouseOver: function(){
            this.bgclass = {bg:true,'bg-fadein':true};
            this.isHover = true;
        },
        mouseLeave: function () {
            this.bgclass = {bg:true,'bg-fadeout':true};
            this.isHover = false;
        },
        get_pic(src){
	  		if(src){
	  			return global.SERVER_URL+ src;
	  		}else{
	  			return "";
	  		}
  		},
  		goSupport(){
  			if(this.product){
  				var path = '/support';
				this.$router.push({
		          path: path,
		          query:{
		          	id:this.product.id
		          }
		        });
		        window.scrollTo(0,0);
  			}
		}
  }
};
</script>

<style scoped>
.home_card{
 	width: 500px;
	height: 398px;
	background: #FFFFFF;
	box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
	opacity: 1;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
}

.bg{
	position: absolute;
	top: -400px;
	left: 0;
	width: 500px;
	height: 400px;
	z-index: 1;
	background: #202B39;
}

.bg-fadein{
	animation: move-in 1s ease-in;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,-0.25,.32,1);
}

.bg-fadeout{
	top:  0;
	left: 0;
	animation: move-out-card 1s ease-in;
	animation-fill-mode: forwards; 
	animation-timing-function: cubic-bezier(.94,0.25,.32,1);
}

@keyframes move-out-card{
    to{
      top: -400px;
      left:0px;
      opacity: 1;
    }
  }

 @-webkit-keyframes move-out-card{
    to{
      top: -400px;
      left:0px;
      opacity: 1;
    }
  }


.left{
	position: relative;
	width: 190px;
	float: left;
	margin-left: 35px;
	z-index: 2;
}

.right{
	position: relative;
	float: left;
	width: 270px;
	height: 398px;
	display: flex;
    justify-content:center;
    align-items:center;
    z-index: 2;
    cursor: pointer;
}

.right img{
	width: 265px;
	height: 320px;
	position: absolute;
	top: 35px;
	left:2.5px;
}

.right .img{
	opacity: 1;
	transition: opacity 1s;
	-webkit-transition: opacity 1s;
	z-index: 5;
}

.right .img_hover{
	opacity: 0;
	transition: opacity 1s;
	-webkit-transition: opacity 1s;
	z-index: 4;
}

.img_hover_active{
	opacity: 1 !important;
}

.img_active{
	opacity: 0 !important;
}

.color_active{
	color: #ffffff !important;
}

.number-font{
	font-size: 20px;
	font-family: Roboto;
	font-weight: bold;
	line-height: 24px;
	color: #202B39;
	opacity: 1;
	transition: color 1s;
	-webkit-transition: color 1s;
}

.number{
	margin-top: 40px;
	margin-bottom: 40px;
}


.des{
	margin-top: 7px;
	width: 100%;
	height: 70px;
	font-size: 16px;
	font-family: Roboto;
	font-weight: 400;
	line-height: 24px;
	color: #202B39;
	opacity: 1;
	transition: color 1s;
	-webkit-transition: color 1s;
}

.buton_normal{
	width: 130px;
	height: 46px;
	border: 1px solid #06A2EC;
	opacity: 1;
	border-radius: 23px;
	background: transparent;
	cursor: pointer;
	color: #06A2EC;
	font-size: 16px;
	text-align:center;
	vertical-align: middle;
	line-height: 46px;
}

.buton_normal:hover{
	color: #FFFFFF;
	background: #06A2EC;
}

.buttons{
	margin-top: 30px;
}

a{
	text-decoration: none;
}

</style>
