<template>
	<div class="subscribe">
			<div class="h1 subscribe">Always Be The First To Know.</div>
			<div class="subscribe_des">Sign up for our newsleter!</div>
			<div class="subscribe_input" :class="{error:isEmailError && isSubmit}">
				<div class="sub_input_left">
					<input type="text"  name="email" v-model="email" maxlength="100" placeholder="Email" />
				</div>
				<div class="sub_input_right" @click="submit">
					Subscribe
				</div>
			</div>
	</div>
</template>

			<script>
export default {
  name: "Subscribe",
  props: {
  },
  data(){
		return {
			email:'',
			isSubmit:false,
		};
	},
	computed:{
		isEmailError(){
			if(this.email){
				var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
				if(reg.test(this.email)){
					return false;
				}
			}
			return true;
		}
	},
	methods:{
		submit(){
			this.isSubmit = true;
			if(this.isEmailError){
				return;
			}
			this.isSubmit = false;
			this.$http.post(global.SERVER_URL+'/openapi/subscribe',{email:this.email},{emulateJSON:true}).then(response => {
	         console.log(response.data);
	         if(response.data && response.data.code ===0){
	         	this.email = "";
	         	this.$toast('Success')
	         }else{
	         	this.$toast('subscribe failed, please try again.')
	         }
	      }, response => {
	         this.$toast('request failed, please try again.')
	      });
		}
	},
};
</script>


<style scoped>
.error{
	box-shadow:0px 0px 20px red !important;
}

.subscribe_input{
	width: 466px;
	height: 56px;
	background: #FFFFFF;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	opacity: 1;
	border-radius: 26px 28px 28px 26px;
	margin:auto;
	margin-top: 60px;
	margin-bottom: 20px;
	display: flex;
}

.sub_input_left{
	flex: 1;
	padding-left: 35px;
	padding-right: 20px;
}

.sub_input_left input{
	height:35px;
	margin-top: 10px;
	border:0px;
	width:100%;
	color: #333; 
	outline: none;
	appearance: none;
}
.sub_input_left input:focus{
	border:0px;
}

.sub_input_right{
	margin:10px 0px; 
	height: 35px;
	border-left:1px solid #EEEEEE;
	font-size: 18px;
	line-height: 16px;
	color: #008FD7;
	cursor: pointer;
	line-height: 35px;
	text-align: center;
	width: 130px;
}

.subscribe_des{
	margin-top: 15px;
	font-size: 24px;
	font-family: Muli;
	font-weight: 400;
	line-height: 16px;
	color: #666666;
}

.subscribe{
	margin-top: 150px;
	font-size: 30px !important;
	font-family: Muli;
}
</style>
