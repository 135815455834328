<template>
  <div class="toast" v-show="visible">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'toast',
  data () {
    return {
      message: '', // 消息文字
      duration: 5000, // 显示时长，毫秒
      closed: false, // 用来判断消息框是否关闭
      timer: null, // 计时器
      visible: false // 是否显示
    }
  },
  mounted () {
    this.startTimer()
  },
  watch: {
    closed (newVal) {
      if (newVal) {
        this.visible = false
        this.destroyElement()
      }
    }
  },
  methods: {
    destroyElement () {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    startTimer () {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.closed = true
          clearTimeout(this.timer)
        }
      }, this.duration)
    }
  }
}
</script>

<style lang="less" scoped>
.toast{
                background-color:#000000;
                opacity:0.65;
                color:#FFF;
                max-width: 200px;
                min-height:20px;
                position:fixed;
                border-radius:6px;
                z-index: 9999;
                text-align: center;
                font-size:18px;
                overflow: hidden;
                margin:0px auto;
                padding:15px;
                left:0;
                right:0; 
                top:50%;
    
            }


</style>