<template>
  <div class="bottom">
    <div class="bottom_container">
      <div class="logo_container">
        <div class="logo">
          <img src="@/assets/logo.png" />
        </div>
        <div class="des">
          <img src="@/assets/email_icon.png" /><span>support@tenvis.com</span>
        </div>
        <div class="des">
          <img src="@/assets/tell_icon.png" /><span>+1(833)438-9277</span>
        </div>
        <div class="urls">
          <a href="https://www.facebook.com/TenvisOfficial/" target="_blank"><img src="@/assets/fb.png" /></a>
          <a href="https://twitter.com/TenvisOfficial" target="_blank"><img src="@/assets/tw.png" /></a>
          <!-- <a href="#"><img src="@/assets/yt.png" /></a> -->
        </div>
      </div>

      <div class="menu">
          <p>Products</p>
          <ul class="list">
            <li v-for="(product,index) in this.$store.state.products"><a @click='goto_product(product.id)'>{{product.model}}</a></li>
          </ul>
      </div>

      <div class="menu">
          <p>About</p>
          <ul class="list">
            <li><a @click="goto('/about')">About us</a></li>
            <li><a @click="goto('/about',1300)">Contact us</a></li>
          </ul>
      </div>

      <div class="menu">
          <p>Support</p>
          <ul class="list">
            <li><a @click="goto('/support')">FAQ</a></li>
          </ul>
      </div>
      <div style="clear: both;height: 1px;"></div>
    </div>
    <div class="copyright">TENVIS@2020 All Rights Reserved</div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  props: {
  },
  methods:{
    goto_product(id){
      window.scrollTo(0,0);
      if(this.$route.name=="Products"){
        var path = '/products?id='+id;
        this.$router.push({
              path: path,
              query:{
                id:id
              }
        }).catch(e=>e);
      }else{
        this.$router.push('/products?id='+id);
      }
    },
    goto(url,scrollto){
      this.$router.push(url);
      if(scrollto){
         this.timer = setTimeout(() => {
            window.scrollTo(0,scrollto);
            clearTimeout(this.timer)
        }, 100)
      }else{
        window.scrollTo(0,0);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.copyright{
  width: 1920px;
  height: 40px;
  font-size: 12px;
  font-family: Muli;
  line-height: 40px;
  margin-top: 30px;
  color: #EEEEEE;
  background: #19222D;
  text-align: center;
}


.bottom_container{
  width: 1390px;
  margin:0 auto; 
  padding-top: 50px;
}

.list{
  list-style: none;
  padding: 0px;
}

.list li{
  height: 40px;
  line-height: 40px;
  padding: 0px;
}

.list a{
  font-size: 16px;
  font-family: Muli;
  font-weight: 400;
  color: #DDDDDD;
  text-decoration: none;
  cursor: pointer;
}

.list a:hover{
  color:#008FD7;
}

.menu p{
  font-size: 20px;
  font-family: Muli;
  font-weight: 400;
  color: #EEEEEE;
}

.menu{
  float: left;
  width: 280px;
}


.urls{
  margin-top: 45px;
}

.urls a{
  margin-right: 35px;
}

.des img{
  width: 20px;
  margin-right: 10px;
}

.logo img{
  width: 166px;
  height: 47px;
  margin-bottom: 25px;
}


.des{
  height: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo_container{
  font-size: 16px;
  color:#EEE;
  float: left;
  margin-right: 150px;
}

.bottom{
  width: 100%;
  background:#202B39; 
  padding: 0px;
}
</style>

