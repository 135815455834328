import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
	state:{
        products:[],
        hots:[],
        request_loading:false,
        message_show:false,
    },
    mutations:{
        edit_products(state,payload){
            state.products = payload.products;
        },
        edit_hots(state,payload){
        	state.hots = payload.products;
        },
        request_loading (state,extra) {
			state.request_loading = extra
		},
        message_show(state,extra) {
            state.request_loading = extra
        }, 
    }
})

export default store